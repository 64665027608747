var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","hide-default-footer":true,"disable-pagination":true,"item-key":"index","loading":_vm.loading,"group-by":"name","empty-group-text":"There are no components"},scopedSlots:_vm._u([{key:"group.action",fn:function(ref){
var items = ref.items;
return [_c('div',{staticClass:"table-actions-bar"},[(_vm.showDeleteSystemBtn)?_c('confirmation-modal',{key:items[0].id,attrs:{"mutation":_vm.DELETE_SYSTEM_MUTATION,"variables":{ id: items[0].id },"title":"Delete System","message":"Are you sure you want to delete System?","ok-btn":{ label: 'Delete' },"update":_vm.onSystemDelete(items[0])},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}},{key:"item.costPerUnit",fn:function(props){return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.onOpen(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{staticClass:"cost-edit-modal",on:{"submit":function($event){$event.preventDefault();return _vm.save(props.item)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('number-text-field',{attrs:{"rules":[function (v) { return (v !== null && v !== '') || 'Cost is Required'; }, _vm.positiveNumber, _vm.floatNumber(2)],"required":"","label":"Edit Cost Per Unit","autocomplete":"off"},model:{value:(_vm.editCost),callback:function ($$v) {_vm.editCost=_vm._n($$v)},expression:"editCost"}})],1)]},proxy:true}],null,true)},[_c('span',{staticClass:"currency-cell"},[_vm._v(_vm._s(_vm._f("currency")(props.item.costPerUnit)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_COMPONENT_MUTATION,"variables":{ id: item.componentId },"title":"Delete Component","message":"Are you sure you want to delete Component?","ok-btn":{ label: 'Delete' },"update":_vm.onComponentDelete(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }