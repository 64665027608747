import { render, staticRenderFns } from "./Database.vue?vue&type=template&id=3183c7f1&"
import script from "./Database.js?vue&type=script&lang=js&"
export * from "./Database.js?vue&type=script&lang=js&"
import style0 from "./Database.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VEditDialog,VForm,VIcon})
